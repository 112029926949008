<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
     
    }
  },
}
</script>
<style>
 @import 'styles/style.css';
@import './font/iconfont.css';
body{
  position: absolute;
  background: #F4F4F4;
  width: 100%;
  height: 100%;

}
.mint-swipe-indicator{
  background: #000;
  border: 1px solid black
}
.mint-swipe-indicator.is-active{
  background: #ddd;
}
</style>
