<template>
  <div class="body">
    <div class="banner"></div>
    <div class="hot">
      <div class="hot_head">爆款推薦</div>
      <div class="hot_book">
        <Row :gutter="2" type="flex" justify="space-around">
            <Col span="7" v-for="item in showHotBooks">
                <div @click="handleGoBook(item.BookId)" >
                    <img :src="item.BookCover" style="display: block; width: 100%; height: 100%" class="hot_book_conver"/>
                    <div class="hot_book_title">{{item.BookName}}</div>
                </div>
            </Col>
        </Row>
        <div class="hot_book_random"><span  @click="handleHotRandom(1)">换一换</span></div>
      </div>
    </div>
    <div class="hot">
      <div class="hot_head">懸念短篇</div>
      <div class="hot_book">
        <Row :gutter="2" type="flex" justify="space-around">
            <Col span="7" v-for="item in showHotBooks_2">
                <div @click="handleGoBook(item.BookId)">
                    <img :src="item.BookCover" style="display: block; width: 100%; height: 100%" class="hot_book_conver"/>
                    <div class="hot_book_title">{{item.BookName}}</div>
                </div>
            </Col>
        </Row>
        <div class="hot_book_random"><span  @click="handleHotRandom(2)">换一换</span></div>
      </div>
    </div>
    <div class="hot">
      <div class="hot_head">心潮澎湃</div>
      <div class="hot_book">
        <Row :gutter="2" type="flex" justify="space-around">
            <Col span="7" v-for="item in showHotBooks_3">
                <div @click="handleGoBook(item.BookId)">
                    <img :src="item.BookCover" style="display: block; width: 100%; height: 100%" class="hot_book_conver"/>
                    <div class="hot_book_title">{{item.BookName}}</div>
                </div>
            </Col>
        </Row>
        <div class="hot_book_random" ><span  @click="handleHotRandom(3)" >换一换</span></div>
      </div>
    </div>
    <div class="hot">
      <div class="hot_head">活动规则</div>
      <div class="hot_rule">
        1、活動期間，全站書籍解鎖限时7折优惠
      </div>
      <div class="hot_rule">
        2、例：《先生厚愛》VIP章節解鎖需要10閱幣，活動期間僅需要7閱幣即可解鎖該VIP章節，以此類推。
      </div>
      <div class="hot_rule">
        <span style="color:red ;">*</span>本活動最終解釋權歸牛牛小說官方所有
      </div>
    </div>
    <div class="foot">
      <div class="foot_logo"></div>
      <div class="foot_title">牛寶們新年快樂 阖家欢乐</div>
    </div>
  </div>
</template>

<script>

import footer from "@/components/footer/index";


export default {
  name: 'index',
 
  data() {
    return {
       showHotBooks:[],
       showHotBooks_2:[],
       showHotBooks_3:[],
       hotBooks:[
        {BookId:4496,BookName:"一夜迷情：总裁轻点我怕疼",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//8cf6c2836ccb437b881665d4f6a631f1.jpg"},
        {BookId:4498,BookName:"亿万老公宠上天 ",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//0cccfe4e9e4744968669fd6432d97b03.jpg"},
        {BookId:4500,BookName:"假爱真做：总裁你别太生猛",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//5dc9608a05b94a1a841c25591e3e048b.jpg"},
        {BookId:4502,BookName:"宠妻入怀情难却",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//4bed2125370d47219096c5df0a21818d.jpg"},
        {BookId:4503,BookName:"将军夫人不好惹",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//f0f87671be4e42c1863d35b7ef2fdc5b.jpg"},
        {BookId:4506,BookName:"军火皇后",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//9b1cf8a487c0470d81681053b9241cd8.jpg"},
        {BookId:4501,BookName:"奉子成婚休想逃",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover//9681796a881a4d989fdf43cb8a0dafe1.jpg"},
        {BookId:4638,BookName:"俏房客",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/f10f3b0ae94c4e19a8e283ab68d18253.jpg"},
        {BookId:4626,BookName:"都市读心高手",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/49b8cce690564ceca9db0bad632bbcc1.jpg"},
       ],
       hotBooks2:[
        {BookId:4849,BookName:"悄悄上位",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/f4874da3815f4438bab24cd51a0e9b84"},
        {BookId:4850,BookName:"悄悄上位-宋成番外",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/2c314aa329d34aca8bb738e7190da28a.jpg"},
        {BookId:4851,BookName:"房东真有毒",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/5b2ff27ef64f4820bdf6bad7d0fc81fd"},
        {BookId:4852,BookName:"我的冤家",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/6fec3faf3cc24a1f8b7107da2882910b"},
        {BookId:4853,BookName:"单恋快滚蛋",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/41651eeb0f114009b5629e3b5ba9e31d"},
        {BookId:4854,BookName:"甜蜜失重",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/19699e640176449a911e5dcd26470956"},
        {BookId:4855,BookName:"追夫",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/9ab27bb872084fe1a86b636e658e305d"},
        {BookId:4856,BookName:"甄南",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/44ad3a9e6485411dafd6144fc96fea65"},
        {BookId:4857,BookName:"被我生扑的学长成了我表哥",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/TxtBooksCover/cd437768a6674ed2ac1b944d3f7ded82"},
       ],
       hotBooks3:[
        {BookId:4831,BookName:"金鳞游天下",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/febebc54b29348fcb57490f87368465e.jpg"},
        {BookId:4779,BookName:"杀手房东俏房客",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/59b617898d1d4a089ab97eb8fb49865c.jpg"},
        {BookId:4846,BookName:"痞子鲜师",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/ab717e47a9c14ed1af57b773ff5d9ff7.jpg"},
        {BookId:4775,BookName:"都市情缘",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/5b073e6ddd664d26912660bb1abdab6f.jpg"},
        {BookId:4817,BookName:"靠近女局：权力的游戏",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/2ef8af238ec847c6a224f81a83ffc8d0.jpg"},
        {BookId:4778,BookName:"校花的贴身绝世高手",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/014feed02b2043f29bde89dd0888c6a1.jpg"},
        {BookId:4779,BookName:"杀手房东俏房客",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/59b617898d1d4a089ab97eb8fb49865c.jpg"},
        {BookId:4786,BookName:"都市之風月奇譚",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/12bb394aee6b4b3c8b7a10dfc8260d68.jpg"},
        {BookId:4780,BookName:"究极绝色诱惑",BookCover:"https://haosainiu-develop.oss-cn-hongkong.aliyuncs.com/BookCover/Big/5db8beefea484e76863081bdfe81b4d8.jpg"},
       ]
    };
  },
  beforeCreate() {
   
},
  created() {
    //this.init()
    this.handleHotRandom(1)
    this.handleHotRandom(2)
    this.handleHotRandom(3)
  },
  mounted() {

  },
  destroyed() {

  },

  beforeDestroy() {
    
  },
  methods: {
   
    handleHotRandom(type)
    {
      switch(type)
      {
        case 1:
          this.showHotBooks=this.shuffle(this.hotBooks);
          break;
        case 2:
          this.showHotBooks_2=this.shuffle(this.hotBooks2);
          break;
        case 3:
          this.showHotBooks_3=this.shuffle(this.hotBooks3);
          break;
      }
    },
    shuffle(array) {
      var newArrar=[]
      while(true)
      {
        var _num = Math.floor(Math.random()*array.length)
        var mm = array[_num];
       
        if(newArrar.length==0)
        {
          newArrar.push(mm);
        }else
        {
         
          var hasBook=false;
           for (let index = 0; index < newArrar.length; index++) {
            const item = newArrar[index];
            if(item.BookId ==mm.BookId){
              hasBook=true;
              break;
            }
           }
           if(!hasBook)
            newArrar.push(mm);
        }
       
        if(newArrar.length==3)
        {
          break;
        }
      }
      return newArrar;
    },
    handleGoBook(bookid)
    {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isAndroid){
          location.href="nnr://hsn.nnreader/book?bookId="+bookid;
      } else if(isiOS){
        location.href ="nnr://hsn.nnreader/bookdetail?bookid="+bookid;
      }
      
    },
    getQueryVariable(variable){
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
      },
  },
  components: {
    "v-footer": footer
  },
}
</script>

<style lang="less" scoped>
.body{
  background-image: url("@/static/img/bj.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
  .banner{
    width: 100%;
    height: 440px;

    // background-image: url("@/static/img/banner.png");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
  }
  .hot{
    width: 85%;
    height: 260px;
    margin:auto;
    background-color: #FFF9E7 ;
    border-top-left-radius: 13.5pt;
    border-top-right-radius:13.5pt;
    border-bottom-left-radius: 13.5pt;
    border-bottom-right-radius:13.5pt;
    border:2.5pt solid #F8CD7D;
    margin-bottom: 30px;
  }
  .hot_head{
    background-image: url("@/static/img/biaoqian.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    
    
    width: 183px;
    height: 31px;
    line-height: 31px;
    margin:auto;
    margin-top: -14px;
    font-size: 18px;
    font-weight: 400;
    color:#FFFFFF ;
    text-align: center;
    vertical-align: middle;

  }
  .hot_book{
    margin:auto;
    text-align: center;
    margin-top: 30px;
  }
.hot_book_conver{
    border-top-left-radius: 5pt;
    border-top-right-radius:5pt;
    border-bottom-left-radius: 5pt;
    border-bottom-right-radius:5pt;
    box-shadow:4px 4px 2px rgba(169, 165, 165, .3);
}
  .hot_book_title{
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    font-weight:bold;
    color: black;
    margin: auto;

    margin-top: 10px;
    width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;

  }
  .hot_book_random{
    font-size: 14px;
    line-height: 16.8px;
    color: #7463ED;
    font-weight: 400;
    margin-top: 20px;
  }
  .hot_rule{
    color: #956929;
    font-size: 15px;
    font-weight: bold;
    margin: 30px 10px 0px 10px;

  }
  .foot{
    width: 100%;
    height: 92px;
    background-image: url("@/static/img/foot.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 20px;
  }
  .foot_logo{
    background-image: url("@/static/img/logo.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin:auto;
    width: 90px;
    height: 30px;
  }
  .foot_title{
    font-size: 14px;
    color:#FFF000 ;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
  }
</style>
