import { createRouter, createWebHistory } from 'vue-router'
import index from '@/pages/index/index'//首页


const routes = [

  {
    path: '/',
    name: 'index',
    component: index
  }
  
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
