<template>
  <div id="bottom">
    <!-- <Link to="https://www.baidu.com" target="_blank">
        <Text type="secondary">Copyright © 2022  All Rights Reserved</Text>
    </Link> -->
      <Button class="affixButton" >去牛牛阅读继续看书</Button>
      <Divider class="affixTip" size="small ">更多精彩情节，尽在牛牛阅读</Divider>
  </div>
</template>

<style scoped>
 #bottom {
    width: 100%;
    height: 150px;
    position: fixed;
    bottom: 0;
    
    background:linear-gradient(rgba(255, 255, 255,0.9), #FFFFFF);
    border-top: 0px solid #F4F4F4;
    text-align: center;
    font-size:12px;
    
}


 .affixButton{
  margin-top: 30px;
  margin-bottom: 30px;
    background-color: #9B54F0;width: 200px;border:0px;text-align: center;
    color:white
  }
  .affixTip{
    font-size: 12px;
  }
</style>